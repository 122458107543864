var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.canWrite('spinners'))?_c('router-link',{staticClass:"btn btn-link my-2",attrs:{"to":{
		name: 'projects-editor-uuid-cuuid-tests-combinations-new',
		params: {
			uuid: _vm.$route.params.uuid,
		   cuuid: _vm.$route.params.cuuid
		}
	}}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" NEW COMBINATIONS REPORT ")]):_vm._e(),_c('div',{staticClass:"my-2"},[(_vm.reports.length > 0)?_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.reports},on:{"on-row-click":_vm.onRowClick}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }