<template>
	<div>
		<router-link v-if="canWrite('spinners')" :to="{
			name: 'projects-editor-uuid-cuuid-tests-combinations-new',
			params: {
				uuid: $route.params.uuid,
			   cuuid: $route.params.cuuid
			}
		}" class="btn btn-link my-2">
			<i class="fa fa-plus"></i>
			NEW COMBINATIONS REPORT
		</router-link>

		<div class="my-2">

			<vue-good-table
					:columns="columns"
					:rows="reports"
					v-if="reports.length > 0"
					@on-row-click="onRowClick"
			/>

		</div>

	</div>
</template>

<script>
	import api from '@/api'
	import SpinnerStatusLabel from '@/components/spinner/spinner-status-label'
	import ReportExcelDownloadButton from "@/components/reports/report-excel-download-button";
	import messageBox from '@/components/modals/alert'
	import SymbolSwitcher from "@/components/slot-config-editor/symbols/symbol-switcher";

	export default {
		data() {
			return {
				reports: [],
				interval: null,
				deleting: false,
				columns: [
					{
						label: 'Date',
						field: 'CreatedAt'
					},
					{
						label: 'Lines',
						field: (row) => {
							return row.ReportOptions.lines
						},
					},
					{
						label: 'Filters',
						field: (row) => {
							return row.ReportOptions.reel_filters.map(rf => rf.slice(0).sort().join("/")).map(r => r.length > 0 ? r : 'ANY').join(" - ")
						},
						tdClass: 'text-monospace',
						html: true,
					},
					{
						label: 'Status',
						field: 'Status',

					},
					{
						label: 'Done',
						field: 'Done',

					},
					{
						label: 'Total',
						field: 'Total',

					}
				]
			}
		},
		async mounted() {
			this.loadReports()
			this.interval = setInterval(this.loadReports, 60000)
		},
		destroyed() {
			clearInterval(this.interval)
		},
		methods: {
			onRowClick(params) {
				this.$router.push({
					name: 'projects-editor-uuid-cuuid-tests-combinations-report_id',
					params: {
						report_id: params.row.Id
					}
				})
			},
			async loadReports() {
				this.reports = await api.CombinationReports.getList({uuid: this.$route.params.cuuid})
			},
			async deleteReport(report_id) {
				this.deleting = true
				await api.Reports.delete(report_id).catch(() => this.deleting = false)
				await this.loadReports()
				this.deleting = false
				messageBox({
					message: `Report deleted!`
				})

			}
		},
		components: {
			SymbolSwitcher,
			ReportExcelDownloadButton,
			SpinnerStatusLabel
		}
	}
</script>
